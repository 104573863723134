import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {
    dateFormatter (value) {
      return moment(value).format('Y-MM-DD')
    },

    dateTimeFormatter (value) {
      return moment(value).format('Y-MM-DD H:mm:ss')
    },

    numberFormatter (value) {
      return numeral(value).format('0,0.00')
    },

    changeMonth (value) {
      let month = 0
      switch (value) {
        case 1:
          month = 'January'
          break
        case 2:
          month = 'February'
          break
        case 3:
          month = 'March'
          break
        case 4:
          month = 'April'
          break
        case 5:
          month = 'May'
          break
        case 6:
          month = 'June'
          break
        case 7:
          month = 'July'
          break
        case 8:
          month = 'August'
          break
        case 9:
          month = 'September'
          break
        case 10:
          month = 'October'
          break
        case 11:
          month = 'November'
          break
        case 12:
          month = 'December'
          break
        default:
          return `${value} here`
      }
      return month
    }
  }
}
